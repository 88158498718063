<template>
  <div class="newxt-steps">
    <div class="head">
      <span>Next Steps</span>
      <artmisads-select-button @click="() => setOpenWelcome(true)" :showNextIcon="true">View Details</artmisads-select-button>
    </div>
    <div class="cont">
      <artmisads-button-special
        size="large"
        @click="() => openPage(item)"
        v-for="item in nextStepsDataList"
        :key="item.type"
      >
        <div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jindu-cu"></use>
          </svg>
          <span>{{ item.name }}</span>
        </div>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-jiantou-you-cu"></use>
        </svg>
      </artmisads-button-special>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

const props = defineProps(["nextStepsData", 'setOpenWelcome']);
const router = useRouter();

const nextStepsDataList = computed(() => {
  let data = [];
  if (props.nextStepsData.length) {
    props.nextStepsData.forEach((item) => {
      if (!item.complete) {
        const newItemData = { ...item };
        if (item.type === "Product") {
          newItemData.link = "/seller/product";
        } else if (item.type === "BrandLogo") {
          newItemData.link = `/seller/brand/detail/${item.typeValue}`;
          // 弹窗
        } else if (item.type === "BrandBio") {
          newItemData.link = `/seller/brand/detail/${item.typeValue}`;
          // 弹窗
        } else if (item.type === "ProductDeal") {
          newItemData.link = `/seller/product`;
          // 弹窗
        } else if (item.type === "Brand") {
          newItemData.link = `/seller/brand/index`;
        } else if (item.type === "Account") {
          newItemData.link = `/seller/account-info`;
        }
        data = [...data, newItemData];
      }
    });
    return data;
  }
});

const openPage = (data) => {
  const { link, type } = data;
  const { href } = router.resolve({ path: link });
  let localStorageName = '';
  if (type === 'BrandLogo' || type === 'BrandBio') {
    localStorageName = 'artemisAds_dashboard_click_brand';
  } else if (type === 'ProductDeal') {
    localStorageName = 'artemisAds_dashboard_click_product_deal'
  }
  if (localStorageName) {
    localStorage.setItem(localStorageName, '1');
  }
  window.open(href, '_self');
};
</script>
<style lang="less" scoped>
.newxt-steps {
  width: 100%;
  padding: 16px 0 16px 16px;
  box-shadow: var(--section-box-shadow-5);
  background-color: var(--color-white);
  border-radius: 8px;
  .head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 16px 16px 0;
    border-bottom: 1px solid var(--border-4);
    > span {
      color: var(--dark-1);
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 28px;
    }
  }
  .cont {
    width: 100%;
    height: 195px;
    padding: 16px 16px 0 0;
    overflow: scroll;
    button {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 8px;
      > div {
        display: flex;
        max-width: calc(100% - 24px);
        svg {
          align-self: center;
        }
        span {
          overflow: hidden;
          margin-left: 8px;
          align-self: center;
          text-overflow: ellipsis;
          max-width: calc(100% - 24px);
        }
      }
      svg {
        width: 16px;
        height: 16px;
        align-self: center;
        fill: var(--dark-4);
      }
    }
  }
}
</style>
