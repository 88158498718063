<template>
  <div class="user-info">
    <div class="head">
      <p>Account Info</p>
    </div>
    <div class="cont">
      <div class="item">
        <div class="input-item">
          <span class="text">Company Name (DBA)</span>
          <artmisads-input maxlength="100" style="width: 400px;" size="middle" v-model:value="props.sellerAccountInfoRequest.dbaName"/>
        </div>
        <div class="input-item">
          <span class="text">Country</span>
          <artmisads-select
            v-model:value="props.sellerAccountInfoRequest.country"
            style="width: 400px;"
            size="middle"
            :options="props.countryList"
          />
        </div>
      </div>
      <div class="item">
        <div class="input-item">
          <span class="text">Address</span>
          <artmisads-input maxlength="100" style="width: 400px;" size="middle" v-model:value="props.sellerBillingAddressRequest.address"/>
        </div>
        <div class="input-item">
          <span class="text">Address Cont.</span>
          <artmisads-input maxlength="100" style="width: 400px;" size="middle" v-model:value="props.sellerBillingAddressRequest.addressCont"/>
        </div>
      </div>
      <div class="item">
        <div class="input-item">
          <span class="text">City</span>
          <artmisads-input maxlength="100" style="width: 400px;" size="middle" v-model:value="props.sellerBillingAddressRequest.city"/>
        </div>
        <div class="input-item">
          <span class="text">State </span>
          <artmisads-input maxlength="100" style="width: 400px;" size="middle" v-model:value="props.sellerBillingAddressRequest.state"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps(['sellerAccountInfoRequest', 'sellerBillingAddressRequest', 'countryList']);

</script>

<style lang="less" scoped>
  .user-info {
    .head {
      display: flex;
      > p {
        font-weight: 600;
        font-size: 20px;
        color: var(--dark-1);
        letter-spacing: 0.5px;
        line-height: 28px;
      }
    }
    .cont {
      padding-bottom: 110px;
      margin-top: 16px;
      .item {
        display: flex;
        margin-top: 24px;
        &:first-child {
          margin-top: 8px;
        }
        .input-item {
          display: flex;
          flex-direction: column;
          width: 400px;
          margin-right: 40px;
          .text {
            color: var(--dark-2);
            font-weight: 500;
            line-height: 18px;
            font-size: 13px;
            margin-bottom: 4px;
            display: flex;
            > span {
              color: var(--red-text);
              margin-left: 3px;
              font-size: 14px;
              font-weight: 600;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
</style>