<template>
  <div class="welcome-wrap">
    <div class="welcome">
      <div class="content">
        <div class="top-cont">
          <h3>Congratulations on registering as an ArtemisAds Seller,</h3>
          <p>Complete the following steps to get started quickly:</p>
          <div class="item item-first">
            <div class="head">
              <span class="circel">1</span>
              <span class="text">Activate Brands</span>
            </div>
            <div class="item-content">
              <a-timeline>
                <a-timeline-item
                  >Click "Brands" on the left menu panel. Locate the Brand you
                  want to activate in the list. In the "Action" column, click
                  "Activate" to complete the activation.</a-timeline-item
                >
                <a-timeline-item
                  >In the "Action" column, click "Set Commission" to set the
                  same Commission for all the Products under this
                  Brand.</a-timeline-item
                >
              </a-timeline>
              <div class="btn">
                <a href="/seller/brand/index">
                  <artmisads-button type="primary">
                    Activate Brands
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-jiantou-you-cu"></use>
                    </svg>
                  </artmisads-button>
                </a>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="head">
              <span class="circel">2</span>
              <span class="text">Activate Products</span>
            </div>
            <div class="item-content">
              <a-timeline>
                <a-timeline-item
                  >Click "Products" on the left menu panel. All the Products
                  corresponding to the activated Brands will be displayed in the
                  list.</a-timeline-item
                >
                <a-timeline-item
                  >In the Products list, you can use functions such as
                  filtering, sorting and searching to find the products you want
                  to promote.</a-timeline-item
                >
                <a-timeline-item
                  >In the "Action" column of the Products list, click "Activate"
                  to activate a certain product.</a-timeline-item
                >
                <a-timeline-item
                  >After activating a product, you can set the Commission Rate
                  for the product you want to promote by clicking "Set
                  Commission".</a-timeline-item
                >
              </a-timeline>
              <div class="btn">
                <a href="/seller/product">
                  <artmisads-button type="primary">
                    Activate Products
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-jiantou-you-cu"></use>
                    </svg>
                  </artmisads-button>
                </a>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="head">
              <span class="circel">3</span>
              <span class="text"
                >Contact us to start promoting your products.</span
              >
            </div>
            <div class="item-content">
              <div class="mail">
                <img src="../../../assets/images/admin/welcome/mail.png" />
                <a>Support@Artemisads.com</a>
              </div>
              <div class="address">
                <img src="../../../assets/images/admin/welcome/address.png" />
                <span>740 E Campbell Rd Ste 700, Richardson, TX 75081</span>
              </div>
              <div class="qr-code">
                <img
                  src="../../../assets/images/admin/welcome/app_downlaod.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-cont">
          <span>Thanks,</span>
          <span>The ArtemisAds Team</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.welcome-wrap {
  width: 100%;
  height: 100%;
  padding: 24px;
}
.welcome {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding-bottom: 40px;
  // padding-top: 40px;
  .warning {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    .show-brand {
      position: absolute;
      bottom: 10px;
      right: 50%;
      margin-right: -320px;
    }
    :deep(.ant-alert) {
      width: 690px;
      padding-bottom: 50px;
    }
  }
  .content {
    align-self: center;
    width: 690px;
    background: var(--color-white);
    box-shadow: var(--section-box-shadow-2);
    border-radius: 8px;
  }
  .item {
    margin-top: 48px;
    .head {
      display: flex;
      .circel {
        width: 24px;
        height: 24px;
        background: var(--primary-icon);
        border-radius: 12px;
        box-shadow: 0px 0px 0px 5px var(--primary-icon-border);
        text-align: center;
        line-height: 24px;
        color: var(--color-white);
        font-weight: 500;
        font-size: var(--font-size-large);
      }
      .text {
        font-size: var(--font-size-h-l);
        color: var(--dark-1);
        font-weight: 500;
        line-height: 24px;
        margin-left: 17px;
      }
    }
    .item-content {
      margin-left: 41px;
      :deep(.ant-timeline) {
        margin-top: 13px;
        .ant-timeline-item-content {
          color: var(--dark-2);
          line-height: 22px;
        }
        .ant-timeline-item-head {
          width: 10px;
          height: 10px;
          background: var(--border-1);
          border: 2px solid var(--color-white);
          border-radius: 5px;
          box-shadow: 0px 0px 0px 2px var(--border-1);
        }
      }
    }
    .btn {
      button {
        svg {
          width: 18px;
          height: 18px;
          margin-left: 9px;
        }
      }
    }
  }
  .item-first {
    margin-top: 42px;
  }
  .top-cont {
    padding: 0 37px 40px 48px;
    > h3 {
      font-size: 28px;
      line-height: 38px;
      color: var(--dark-1);
    }
    > p {
      margin-top: 12px;
      line-height: 20px;
      color: var(--dark-1);
    }
    .address,
    .mail {
      display: flex;
      > img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        align-self: center;
      }
    }
    .address {
      margin-top: 12px;
      > span {
        font-size: var(--font-size-large);
        letter-spacing: 0;
        line-height: 24px;
        color: var(--dark-2);
      }
    }
    .mail {
      margin-top: 16px;
      > a {
        height: 24px;
        font-size: var(--font-size-large);
        color: var(--dark-1);
        letter-spacing: 0;
        line-height: 24px;
        text-decoration: underline;
      }
    }
    .qr-code {
      margin-top: 16px;
      width: 100px;
      height: 100px;
      box-shadow: var(--section-box-shadow-2);
      border-radius: 4px;
    }
  }
  .bottom-cont {
    height: 64px;
    background: var(--bg-gray-1);
    border-radius: 0 0 8px 8px;
    text-align: right;
    display: flex;
    flex-direction: column;
    padding-right: 24px;
    padding-top: 14px;
    > span {
      font-size: 13px;
      color: var(--dark-4);
      text-align: right;
      line-height: 18px;
    }
  }
}
</style>
