<template>
  <div ref="newUserModalRef" class="new-user-modal">
    <artmisads-modal
      :open="true"
      :width="1200"
      :destroyOnClose="true"
      :getContainer="() => newUserModalRef"
      :footer="null"
    >
      <a-spin :spinning="spinning">
       <div class="new-user-cont">
          <div class="bg">
            <img class="bg1" alt="artemisAds" src="../../../../assets/images/new-user-modal/new_user_modal_bg1.png" />
            <img  class="bg2"  alt="artemisAds" src="../../../../assets/images/new-user-modal/new_user_modal_bg2.png" />
          </div>
          <div class="cont">
            <div class="head">
              <p class="title">👏 Welcome to ArtemisAds</p>
              <p class="text">Follow these steps to ﬁnalize the setup, and your product will start being promoted by the publishers.</p>
            </div>
            <div class="step-wrap">
              <step :stepNum="stepNum"/>
            </div>
            <div class="main-cont">
              <selectBrand
                v-if="stepNum === 0"
                :brandList="brandList"
                :selectedRowKeys="selectedRowKeys"
                :onSelectChange="onSelectChange"
                :changeBrandList="changeBrandList"
              />
              <info
                v-if="stepNum === 1"
                :sellerBillingAddressRequest="sellerBillingAddressRequest"
                :sellerAccountInfoRequest="sellerAccountInfoRequest"
                :countryList="countryList"
              />
              <qrCode
                v-if="stepNum === 2"
              />
            </div>
          </div>
          <div class="btn" :class="stepNum === 2 ? 'single-btn': ''">
            <span  v-if="stepNum < 2" @click="() => nextStep('skip')">Skip</span>
            <artmisads-button @click="nextStep" v-if="stepNum < 2" :disabled="!selectedRowKeys.length && stepNum === 0" type="primary" size="middle" >Next({{stepNum+1}}/3)</artmisads-button>
            <artmisads-button v-else type="primary" @click="sellerOnboardingCompleted" size="middle">Start</artmisads-button>
          </div>
       </div>
      </a-spin>
    </artmisads-modal>
  </div>
</template>

<script setup>
import { orderBy } from 'lodash';
import { useRouter } from "vue-router";
import { Form } from 'ant-design-vue';
import { ref, reactive, onMounted, onBeforeMount } from "vue";
import { sellerDashboardApi, adminApi } from "~/server";
import { connectionApi } from "@/server/seller/index";
import step from './step.vue';
import qrCode from './qrCode.vue';
import info from './info.vue';
import selectBrand from './selectBrand.vue';
import jwt from 'jsonwebtoken';
import { useUserInfoStore } from '~/storeData/index';

const props = defineProps(['setShowNewUserModal']);

const store = useUserInfoStore();

const countryList = ref([]);
const selectedRowKeys = ref([])
const checkedList = ref([]);
const brandList = ref([]);
const stepNum = ref(0);
const spinning = ref(false);
const newUserModalRef = ref();

const sellerBillingAddressRequest = ref({
  address: '',
  addressCont: '',
  city: '',
  state: ''
})
const sellerAccountInfoRequest = ref({
  dbaName: '',
  country: '',
})
const userInfo = ref({
  brandIds: [],
  onlyActivebrandIds: [],
  operationType: 'Finish', //Skip
})

const changeBrandList = (id, valueName) => {
  let newBrandList = [ ...brandList.value ];
  const index = newBrandList.findIndex(i => id === i.id);
  if (valueName === 'allProducts') {
    newBrandList[index].allProducts = true;
    newBrandList[index].productsLater = false;
  } else if (valueName === 'productsLater') {
    newBrandList[index].productsLater = true;
    newBrandList[index].allProducts = false;
  }
  brandList.value = newBrandList;
}

const sellerOnboardingCompleted = async () => {
  spinning.value = true;
  let paramsData = {}, brandIds = [], onlyActivebrandIds = [];
  if (selectedRowKeys.value.length) {
    selectedRowKeys.value.forEach(item => {
      const isAllProducts = brandList.value.find(i => i.id === item).allProducts;
      if (isAllProducts) {
        brandIds = [...brandIds, item];
      } else {
        onlyActivebrandIds = [...onlyActivebrandIds, item];
      }
    })
    userInfo.value.brandIds = brandIds;
    userInfo.value.onlyActivebrandIds = onlyActivebrandIds;
  }
  paramsData = userInfo.value;
  paramsData.sellerBillingAddressRequest = sellerBillingAddressRequest.value;
  paramsData.sellerAccountInfoRequest = sellerAccountInfoRequest.value;
  const res = await sellerDashboardApi.sellerOnboardingCompleted(paramsData);
  const { data, success } = res;
  if (success) {
    const userToken = data.token;
    const userInfo = jwt.decode(userToken);
    store.setUserInfo(userInfo, userToken);
    props.setShowNewUserModal(false);
  }
  spinning.value = false;
}

const onSelectChange = keys => {
  selectedRowKeys.value = keys;
};

const nextStep = (type) => {
  if (type === 'skip' && stepNum.value === 1) {
    sellerBillingAddressRequest.value = {
      address: '',
      addressCont: '',
      city: '',
      state: ''
    };
    sellerAccountInfoRequest.value = {
      dbaName: '',
      country: '',
    };
  }  else if (type === 'skip' && stepNum.value === 0) {
    selectedRowKeys.value = [];
  }
  stepNum.value = stepNum.value + 1;
}

const changeBrand = (e, value) => {
  const checked = e.target.checked;
  if (checked) {
    checkedList.value = [...checkedList.value, value];
  } else {
    checkedList.value = checkedList.value.filter(item => item !== value);
  }
}

const getSellerBrandsCountry = async () => {
  spinning.value = true;
  const res = await adminApi.getSellerBrands();
  const countryListRes = await connectionApi.getSellerAccountInfoGetCountry();
  const { success, data } = res;
  if (success) {
    brandList.value = orderBy(data, 'amazonBrandName').map(item => {
      item.allProducts = true;
      item.productsLater = false;
      return item;
    });
  }
  if (countryListRes.success) {
    const listData = countryListRes.data.map(item => {
      item.label = item.englishName
      item.value = item.countryCode;
      return item;
    });
    countryList.value = listData;
  }
  spinning.value = false;
}

onBeforeMount(() => {
  getSellerBrandsCountry();
});

</script>

<style lang="less" scoped>
.new-user-modal {
  :deep(.ant-modal) {
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
  .new-user-cont {
    position: relative;
    // height: 678px;
    background-image: linear-gradient(170deg, #E2D9FC 0%, #FCF0FA 16%, #FBFAFF 41%, #FBFBFE 100%);
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06), 0 16px 48px 0 rgba(0, 0, 0, 0.1), inset 2px 2px 0 0 rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      z-index: 0;
      .bg1 {
        width: 324px;
        height: 247px;
      }
      .bg2 {
        width:  125px;
        height: 125px;
        margin-right: 49px;
        margin-top: 9px;
      }
    }
  }
  .cont {
    padding: 40px 40px 0 40px;
  }
  .head {
    .title {
      color: var(--dark-1);
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: 0.69px;
      text-align: left;
    }
    .text {
      margin-top: 5px;
      font-weight: 400;
      font-size: 16px;
      color: var(--dark-2);
      letter-spacing: 0.4px;
      line-height: 24px;
    }
  }
  .step-wrap {
    margin-top: 12px;
    margin-bottom: 32px;
  }
  .btn {
    display: flex;
    padding: 12px 24px;
    border-top: 1px solid var(  --border-4);
    justify-content: space-between;
    &.single-btn {
      justify-content: flex-end;
    }
    > span {
      cursor: pointer;
      font-weight: 400;
      font-size: 13px;
      color: var(--dark-3);
      letter-spacing: 0.3px;
      line-height: 20px;
      align-self: center;
    }
  }
}
</style>
