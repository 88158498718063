<template>
  <div class="select-brand">
    <div class="head">
      <p>Select Brands to Active</p>
      <span>(Please select at least one brand）</span>
    </div>
    <div class="table-cont">
      <artmisads-table
        :columns="columns"
        :row-selection="{
          selectedRowKeys: props.selectedRowKeys,
          onChange: props.onSelectChange,
        }"
        row-key="id"
        :data-source="props.brandList"
        :scroll="{ y: 264 }"
      >
        <template #bodyCell="{ column, record, text }">
          <template v-if="column.name === 'allProducts'">
            <div v-if="text && props.selectedRowKeys?.find(i => i === record.id)" class="circle-wrap">
              <span class="active-circle">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-duigou-jiacu"></use>
                </svg>
              </span>
            </div>
            <div class="circle-wrap" v-else  @click="() => props.changeBrandList(record.id, 'allProducts')">
              <span class="circle"></span>
            </div>
          </template>
          <template v-if="column.name === 'productsLater'">
            <div v-if="text && props.selectedRowKeys?.find(i => i === record.id)" class="circle-wrap">
              <span class="active-circle">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-duigou-jiacu"></use>
                </svg>
              </span>
            </div>
            <div class="circle-wrap" v-else @click="() => props.changeBrandList(record.id, 'productsLater')">
              <span class="circle"></span>
            </div>
          </template>
          <template v-if="column.name === 'brandName'">
            <span class="brand-name">{{text}}</span>
          </template>
        </template>
      </artmisads-table>
    </div>
  </div>
</template>
<script setup>

const props = defineProps(['brandList', 'onSelectChange', 'selectedRowKeys', 'changeBrandList']);

const columns = [
    {
      title: 'Brand',
      dataIndex: 'amazonBrandName',
      name: 'brandName',
      width: '50%',
    },
    {
      title: 'Activate All Products',
      dataIndex: 'allProducts',
      name: 'allProducts',
      width: '25%',
    },
    {
      title: 'Activate Products Manually Later',
      dataIndex: 'productsLater',
      name: 'productsLater',
      width: '25%',
    },
  ]

</script>

<style lang="less" scoped>
  .select-brand {
    padding-bottom: 54px;
    .head {
      display: flex;
      > p {
        font-weight: 600;
        font-size: 20px;
        color: var(--dark-1);
        letter-spacing: 0.5px;
        line-height: 28px;
      }
      > span {
        color: var(--dark-3);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.4px;
        text-align: left;
        margin-left: 8px;
        align-self: center;
      }
    }
    .table-cont {
      margin-top: 16px;
      :deep(.ant-table) {
        .brand-name {
          font-weight: 600;
          font-size: 14px;
          color: var(--dark-1);
          letter-spacing: 0.4px;
          line-height: 19px;
        }
        .circle-wrap {
          cursor: pointer;
        }
        .active-circle {
          display: flex;
          justify-content: center;
          width: 16px;
          height: 16px;
          background: #FFFFFF;
          border: 1px solid var(--border-1);
          box-shadow: 0 1px 0 0 rgba(15, 6, 41, 0.05);
          border-radius: 8px;
          svg {
            width: 12px;
            height: 12px;
            align-self: center;
            fill: var(--border-1);
          }
        }
        .circle {
          cursor: pointer;
          display: flex;
          width: 16px;
          height: 16px;
          background: #FFFFFF;
          border: 1px solid var(--border-1);
          box-shadow: 0 1px 0 0 rgba(15, 6, 41, 0.05);
          border-radius: 8px;
        }
      }
    }
  }
</style>