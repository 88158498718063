<template>
  <div class="general-view">
    <div class="brands">
      <p>Brands</p>
      <a class="item" href="/seller/brand/index">
        <div class="cont">
          <span class="name">
            <span>All Brands</span>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-jiantou-you-cu"></use>
            </svg>
          </span>
          <span class="num">{{dataStatistics.totalBrands}}</span>
        </div>
      </a>
      <a class="item" href="/seller/brand/index">
        <div class="cont">
          <span class="name">
            <span>
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-duigou-jiacu"></use>
              </svg>
              Active Brands
            </span>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-jiantou-you-cu"></use>
            </svg>
          </span>
          <span class="num">{{dataStatistics.totalActiveBrands}}</span>
        </div>
      </a>
    </div>
    <div class="products">
      <p>Products</p>
      <a class="item" href="/seller/product">
        <div class="cont">
          <span class="name">
            <span>All Products</span>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-jiantou-you-cu"></use>
            </svg>
          </span>
          <span class="num">{{dataStatistics.totalProducts}}</span>
        </div>
      </a>
      <div class="item" @click="openProduct">
        <div class="cont">
          <span class="name">
            <span>
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-duigou-jiacu"></use>
              </svg>
              Active Products
            </span>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-jiantou-you-cu"></use>
            </svg>
          </span>
          <span class="num">{{dataStatistics.totalActiveProducts}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { computed } from 'vue';
  import { useRouter } from "vue-router";

  const router = useRouter();
  const props = defineProps(['dataStatistics']);

  const openProduct = () => {
    localStorage.setItem('artemisAds_dashboard_click_active_products', '1');
    const { href } = router.resolve({ path: '/seller/product' });
    window.open(href, '_self');
  }

</script>
<style lang="less" scoped>
  .general-view {
    display: flex;
    width: 100%;
    .products {
      margin-left: 16px;
    }
    .brands, .products {
      padding: 16px;
      box-shadow: var(--section-box-shadow-5);
      background-color: var(--color-white);
      border-radius: 8px;
      width: 50%;
      > p {
        height: 28px;
        color: var(--dark-1);
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.5px;
        line-height: 28px;
        padding-bottom: 8px;
      }
    }
    .item {
      display: flex;
      height: 96px;
      background: var(--bg-gray-1);
      border-radius: 8px;
      padding: 20px 12px 16px 20px;
      margin-top: 16px;
      cursor: pointer;
      transition: .4s ease;
      &:hover {
        background-color: var(--bg-gray-8);
      }
      &:last-child {
        margin-top: 8px;
      }
      
      .cont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        .name {
          display: flex;
          justify-content: space-between;
          > span {
            display: flex;
            height: 20px;
            color: var(--dark-2);
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            align-self: center;
            > svg {
              width: 20px;
              height: 20px;
              fill: var(--green-3);
              margin-right: 4px;
              align-self: center;
            }
          }
          > svg {
            width: 14px;
            height: 14px;
            fill: var(--dark-2);
            align-self: center;
          }
        }
        .num {
          color: var(--dark-1);
          height: 32px;
          font-weight: 600;
          font-size: 28px;
          line-height: 32px;
          margin-top: 4px;
        }
      }
    }
  }
</style>
