<template>
  <div class="step">
    <div class="item" :class="props.stepNum === 0 ? 'item-now' : 'active-item'">
      <span  v-if="props.stepNum !== 0">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-duigou-jiacu"></use>
        </svg>
      </span>
      <span v-else>1</span>
      <div class="text">
        <div class="line-text">
          <span class="name">Activate Brands and Products</span>
          <span class="line"></span>
        </div>
        <span class="bottom-text">Show your product to the pubilshers</span>
      </div>
    </div>
    <div class="item" :class="[props.stepNum === 1 ? 'item-now' : '', props.stepNum === 2 ? 'active-item' : '']">
      <span  v-if="props.stepNum === 2">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-duigou-jiacu"></use>
        </svg>
      </span>
      <span v-else>2</span>
      <div class="text">
        <div class="line-text">
          <span class="name">Account Info</span>
          <span class="line line-2"></span>
        </div>
        <span class="bottom-text">Secure your account and improve service</span>
      </div>
    </div>
    <div class="item" :class="props.stepNum === 2 ? 'item-now' : ''">
      <span>3</span>
      <div class="text">
        <div class="line-text">
          <span class="name">Finish</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

const props = defineProps(['stepNum']);

</script>

<style lang="less" scoped>
  .step {
    position: relative;
    z-index: 1;
    display: flex;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.04), 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 22px 0 22px 46px;
    .item {
      display: flex;
      &.item-now {
        > span {
          box-shadow: 0 0 0 6px var( --primary-bg-3);
          background-color: var( --primary);
          color: #FFFFFF;
        }
        .text {
          .line-text {
            .name {
              color: var(--dark-1);
            }
          }
          .bottom-text {
            color: var(--dark-2);
          }
        }
      }
      &.active-item {
        > span {
          box-shadow: none;
          background-color: var(--green-9);
          display: flex;
          justify-content: center;
          svg {
            align-self: center;
            width: 22px;
            height: 22px;
            fill: var(--green-1);
          }
        }
        .text {
          .line-text {
            .name {
              color: var(--dark-1);
            }
            .line {
              background-color: var(  --border-6);
            }
          }
          .bottom-text {
            color: var(--dark-2);
          }
        }
      }
      > span {
        width: 40px;
        height: 40px;
        background-color: var(--bg-gray-5);
        font-weight: 600;
        font-size: 16px;
        color: var(--dark-1);
        letter-spacing: 0.4px;
        line-height: 40px;
        border-radius: 100%;
        text-align: center;
        margin-right: 12px;
      }
      .text {
        display: flex;
        flex-direction: column;
        .line-text {
          display: flex;
          margin-top: 8px;
          margin-right: 12px;
          .name {
            font-weight: 600;
            font-size: 16px;
            color: var(--dark-3);
            letter-spacing: 0.4px;
            line-height: 24px;
          }
          
          .line {
            width: 115px;
            height: 4px;
            background-color: var( --border-4);
            border-radius: 2px;
            align-self: center;
            margin-left: 12px;
            &.line-2 {
              width: 291px;
            }
          }
        }
        .bottom-text {
          font-weight: 400;
          font-size: 14px;
          color: var(--dark-3);
          letter-spacing: 0.4px;
          line-height: 20px;
          margin-top: 2px;
        }
      }
    }
  }
</style>