<template>
  <div class="filterBar" ref="marketplaceRef">
    <div class="filterBar-content">
      <div class="date">
        <span>Date Range: </span>
        <artemisads-range-picker
          :allowClear="false"
          :value="hackValue || dateParams"
          @openChange="onOpenChange"
          :presets="RANGE_PRESETS"
          :disabled-date="disabledDate"
          @change="onChangeDate"
          @calendarChange="onCalendarChange"
        />
        <span class="vs">VS</span>
        <artemisads-range-picker
          :value="vsDateParams"
          disabled
        />
      </div>
    </div>
  </div>
</template>
<script setup>
  import moment from 'moment';
  import { computed, ref, watch } from 'vue';

  import { COUNTRY_BASE } from '~/contants/common';
  import { RANGE_PRESETS } from '~/contants/reports';
  import Icon from '~/components/Icon.vue';

  const dateParams = ref([
    moment().subtract(30, 'day'), moment()
  ])
  const brandOptions = ref([]);
  const productOptions = ref([]);
  const dates = ref();
  const hackValue = ref();
  const marketplaceRef = ref(null);
  const props = defineProps(['params', 'dateParams', 'setParams', 'platform']);

  const vsDateParams = computed(() => {
    let vsValue = [];
    if (dateParams.value.length === 2 && hackValue.value === undefined) {
      const startDate = dateParams.value[0].valueOf();
      const endDate = dateParams.value[1].valueOf();
      const intervalDays = moment(moment(endDate)).diff(moment(moment(startDate)), 'days') + 1;
      vsValue[0] = moment(startDate).subtract(intervalDays, 'day');
      vsValue[1] = moment(endDate).subtract(intervalDays, 'day');
    }
    return vsValue;
  })

  const disabledDate = current => {
    let notDate = false, tooLate = false, tooEarly = false;
    notDate = current.valueOf() > moment().valueOf();
    if (dates.value && dates.value.length) {
      tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 365;
      tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 365;
    }
    return notDate || tooEarly || tooLate;
  };

  const onOpenChange = open => {
    if (open) {
      dates.value = [];
      hackValue.value = [];
    } else {
      hackValue.value = undefined;
    }
  };
  const onChange = val => {
    dateParams.value = val;
  };
  const onCalendarChange = val => {
    dates.value = val;
  };

  const onChangeDate = val => {
    if (val) {
      dateParams.value = val;
      const dateTime = {
        startReportDateTimestamp: val[0].valueOf(),
        endReportDateTimestamp: val[1].valueOf(),
      };
      props.setParams(dateTime);
    } else {
      dateParams.value = [];
    }
  }
  
  const onChangeCountry = marketplace => {
    props.setParams({ marketplace });
  }

</script>
<style lang="less" scoped>
  .filterBar {
    display: flex;
    padding: 0 4px;
    margin-bottom: 20px;
    margin-top: 4px;
    :deep(.country-select-item) {
      .ant-select-item-option-content {
        display: flex;
        svg {
          align-self: center;
          margin-right: 4px;
        }
      }
    }
    .filterBar-content {
      padding: 16px;
      background-color: var(--bg-gray-1);
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
    }
  }
  .marketplace, .date {
    display: flex;
    > span {
      color: var(--dark-2);
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 20px;
      align-self: center;
      margin-right: 8px;
    }
    .vs {
      margin-left: 8px;
      font-weight: 500;
    }
  }
  .marketplace {
    display: flex;
    align-content: center;
    &:deep{
      .ant-select-selection-item,.ant-select-item-option-content{
        display: flex;
        align-items: center;
        .iconfont{
          margin-right: 4px;
          font-size: 18px;
        }
      }
    }
  }
  
</style>
