<template>
  <div class="connection-modal" ref="connectionModal">
    <a-modal
      v-model:open="configs.waitingOpen"
      :closable="false"
      :width="480"
      :getContainer="() => connectionModal"
      :destroyOnClose="true"
      :maskClosable="false"
      :footer="null"
    >
      <div class="content">
        <a-spin size="large" />
        <p>Please wait… this will just take a few seconds.</p>
      </div>
      <template #title>
        <div class="modal-title">Connecting your Amazon account</div>
      </template>
    </a-modal>
    <a-modal
      v-model:open="configs.isOldUser"
      :closable="false"
      :width="650"
      :getContainer="() => connectionModal"
      :destroyOnClose="true"
      :maskClosable="false"
      :footer="null"
    >
      <div class="old-content">
        <div>
          Following a recent platform upgrade, you are required to log in to your Amazon Seller Name again to reestablish authorization and continue using our services.
        </div>
        <div class="text">
          We sincerely apologize for any inconvenience this may cause and greatly appreciate your understanding.
        </div>
        <div class="confirm">
          Please ensure that the account you reconnect is the same as the one previously linked
        </div>
        <div class="amazon-button">
          <a id="LoginWithAmazon" @click.prevent="onSubmit">
            <img border="0" alt="Login with Amazon"
                src="http://g-ecx.images-amazon.com/images/G/01/lwa/btnLWA_gold_312x64._CB372226922_.png"
                 />
          </a>
        </div>
      </div>
      <template #title>
        <div class="modal-title">Reconnect by Logging in with Amazon</div>
      </template>
    </a-modal>
    <artmisads-modal
      v-model:open="configs.isFailed"
      :width="600"
      :getContainer="() => connectionModal"
      :footer="null"
      @cancel="setModalType('fail')"
    >
      <div class="failed-content">
        <div>
          The account you are attempting to connect does not match the one previously linked. Please try again.
        </div>
        <div>
          The Advertiser Name linked to your previous Seller account is:
        </div>
        <div class="account">
          <span v-for="(name,index) in configs.data" :key="index">
            {{ name }};
          </span>
        </div>
        <div>For further assistance, please reach out to us at </div>
        <div>
           <a class="email">Support@ArtemisAds.com</a>
        </div>
      </div>
      <template #title>
        <div class="modal-title">Connection failed</div>
      </template>
    </artmisads-modal>
    <div id="amazon-root"></div>
  </div>
</template>
<script setup>
import { ref, onMounted,watch } from "vue";
import { useRoute,useRouter} from "vue-router";
const { configs } = defineProps(["configs"]);
const emit =defineEmits(['change','bind'])
const route=useRoute();
const router=useRouter();
const connectionModal = ref();
onMounted(() => {
  amazonLoginInitialize();
  const amazonAuthorizationCode = route?.query?.code;
  if (amazonAuthorizationCode) {
    router.replace({name:route.name});
    emit('bind',amazonAuthorizationCode);
  }
});
function amazonLoginInitialize() {
  const CLIENT_ID =
    "amzn1.application-oa2-client.d1775637c50f46d4b039895ab42f2dda";
  window.onAmazonLoginReady = function () {
    amazon.Login.setClientId(CLIENT_ID);
  };
  (function (d) {
    var a = d.createElement("script");
    a.type = "text/javascript";
    a.async = true;
    a.id = "amazon-login-sdk";
    a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
    d.getElementById("amazon-root").appendChild(a);
  })(document);
}
function onSubmit() {
  const options = {};
  options.scope = "advertising::campaign_management profile";
  options.response_type = "code";
  // options.popup = false;
  amazon.Login.authorize(options, `${location.origin}/seller/dashboard`);
  return false;
}
const setModalType=(type)=>{
  emit('change',type)
}
</script>
<style lang="less" scoped>
.connection-modal {
  .modal-title {
    text-align: center;
    font-size: 24px;
  }
  .content {
    padding-top: 48px;
    width: 100%;
    height: 240px;
    background: var(--color-white);
    // box-shadow: var(--section-box-shadow-2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > p {
      margin-top: 16px;
      width: 239px;
      height: 40px;
      text-align: center;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: var(--dark-2);
    }
  }
  .old-content{
    .text{
      margin: 16px 0;
    }
    .confirm{
      margin-bottom: 24px;
      color: var(--error-1);
    }
    .amazon-button {
        display: flex;
        align-items: center;
        justify-content: center;
        > a {
          display: block;
          width: 195px;
          height: 40px;
          border-radius: 2px;
          text-align: center;
          margin-right: 8px;
          > img {
            width: 193px;
            height: 40px;
          }
        }
      }
  }
  .failed-content{
    margin-bottom: 16px;
    .account{
      margin: 24px 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      >span{
        margin-right: 10px;
        font-size: 20px;
        font-weight: 500;
      }
    }
    .email{
      text-decoration: underline;
      color: var(--dark-2);
      font-weight: 500;
      &:hover{
        color: var(--dark-1);
      }
    }
  }
}
</style>
